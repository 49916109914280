import { SiteConfig } from "../../types";
import { quizzes } from "./quizzes";

export const _config: SiteConfig = {
  isDefault: false,
  showTidio: false,
  serviceName: "MyBerlitz Speaking Tutor",
  pwaServiceName: "Speaking Tutor",
  parentService: {
    name: "Learning Path",
    link: "https://portal.berlitz-platforms.io/learning-path/",
  },
  appLogoPath: "/partner/berlitz/app-logo.png",
  appLogoHeight: "auto",
  landingLogoPath: "/partner/berlitz/logo.jpg",
  hubLogoPath: "/partner/berlitz/hub-logo.png",
  hubFixedPrice: true,
  landingLogoHeight: "40px",
  mobileLogoPath: "/partner/berlitz/mobile-logo.png",
  favicon: "/partner/berlitz/favicon.ico",
  pwaIcons: [
    {
      src: "/partner/berlitz/app-icon.png",
      type: "image/png",
      sizes: "192x192",
    },
    {
      src: "/partner/berlitz/app-icon.png",
      type: "image/png",
      sizes: "256x256",
    },
    {
      src: "/partner/berlitz/app-icon.png",
      type: "image/png",
      sizes: "512x512",
    },
  ],
  overrideLanding: true,
  emailsSender: "info@lang-services.com",
  customContent: {
    baseLanguage: "en-US",
    roles: [],
    quizzes,
  },
  supportLink: "https://portal.berlitz-platforms.io/support/contact-us/",
  settingsVoicePreference: "Microsoft Server Speech Text to Speech Voice (en-US, AvaNeural)",
  // practiceLanguageOptions: ["en-US", "en-GB", "en-AU", "en-IE", "en-NZ", "en-CA", "en-IN"],
  // userLanguageOptions: [
  //   "en-US",
  //   "en-GB",
  //   "es-MX",
  //   "de-DE",
  //   "ja-JP",
  //   "pt-BR",
  //   "fr-FR",
  //   "it-IT",
  //   "pl-PL",
  //   "ko-KR",
  //   "he-IL",
  //   "nl-NL",
  //   "zh-CN",
  // ],
  settingsShowSuggestions: false,
  settingsShowChatTranslations: false,
  settingsPlaybackUiAudio: true,
  settingsShowLiveFeedback: false,
  reviewLink: null,
  hideIntroFlow: true,
  hideManageAccountLink: true,
  hideDashboardStreaks: true,
  hideDashboardConversationHistory: true,
  hideSpeechInputSettings: true,
  hideAsideMenuSettings: true,
  hideAsideMenuTranslator: true,
  hideAsideMenuConversations: false,
  hideTranslationSettings: true,
  hideCustomContent: true,
  emailInvitationSubject: "You have been invited to MyBerlitz Speaking Tutor",
  emailInvitationText: `
  You have been invited to MyBerlitz Speaking Tutor 🎉
    
  We have created a user for you with the following details:
  Email: {email}
  Password: {password}

  Follow this link to accept the invitation:
  {link}
  `,
  copyright: "Berlitz",
  // practiceLanguageLock: "en-US",
  // userLanguageLock: "pl-PL",
  showLanguageGuides: false,
  bioPrompt: `
    You are a MyBerlitz Speaking Tutor at Berlitz. At Berlitz, we believe language is key to global participation, connection, and understanding, fostering a better world. We champion language and cultural understanding to drive progress. Despite innate communication needs, learning new languages often brings apprehension. Berlitz provides the fastest, most effective way to learn confidently and understand others, using the Berlitz Method®. Our passion is language and cultural understanding; our purpose is to open the world.
    `,
  fonts: {
    heading: `"IBM Plex Sans", sans-serif`,
    body: `"IBM Plex Sans", sans-serif`,
  },
  fontLink: "/partner/berlitz/fonts.css",
  siteSpacing: {
    desktopSidebarLogoTopOffset: "0",
  },
  siteColors: {
    special: {
      desktopSidebar: "#004FEF",
      desktopSidebarBorderColor: "#e2e8f0",
      desktopSidebarText: "#fff",
      desktopSidebarTextHover: "#002b82",
      chatAsideMenu: "#1D1D1D",
      mobileNavBackgroundColor: "white",
      mobileNavBackgroundContrastColor: "#1D1D1D",
      mobileNavBorderColor: "#eee",
      speakInputIdle: "var(--chakra-colors-brand-primary-600)",
      speakInputRecording: "var(--chakra-colors-brand-primary-900)",
      chatIconColor: "var(--chakra-colors-brand-primary-main)",
    },
  },
  colors: {
    primary: {
      main: "#004FEF",
      mainContrast: "#FCFCFC",
      "50": "#f4f7fe",
      "100": "#d3e1fc",
      "200": "#acc6fa",
      "300": "#7da5f7",
      "400": "#6192f5",
      "500": "#3977f3",
      "600": "#145df0",
      "700": "#0045d2",
      "800": "#003bb2",
      "900": "#002b82",
    },
    secondary: {
      main: "#643BA2",
      mainContrast: "#FCFCFC",
      "50": "#f8f6fb",
      "100": "#e4ddef",
      "200": "#cdc0e1",
      "300": "#b19dd0",
      "400": "#a289c7",
      "500": "#8d6eba",
      "600": "#7b58b0",
      "700": "#663ea3",
      "800": "#57338d",
      "900": "#3f2566",
    },
    tertiary: {
      main: "#3DDC97",
      mainContrast: "#FCFCFC",
      "50": "#eafbf4",
      "100": "#a7efd0",
      "200": "#49de9d",
      "300": "#34bb81",
      "400": "#2ea873",
      "500": "#278d61",
      "600": "#217752",
      "700": "#1b6042",
      "800": "#165138",
      "900": "#103b28",
    },
    light: {
      main: "#f8f8f8",
      alt: "#f8f8f8",
    },
    // https://palette.saas-ui.dev/
    gray: {
      "50": "#fafafa",
      "100": "#f1f1f1",
      "200": "#e7e7e7",
      "300": "#d4d4d4",
      "400": "#adadad",
      "500": "#7f7f7f",
      "600": "#545454",
      "700": "#373737",
      "800": "#202020",
      "900": "#191919",
    },
    purple: {
      "50": "#f8f6fb",
      "100": "#e4ddef",
      "200": "#cdc0e1",
      "300": "#b19dd0",
      "400": "#a289c7",
      "500": "#8d6eba",
      "600": "#7b58b0",
      "700": "#663ea3",
      "800": "#57338d",
      "900": "#3f2566",
    },
    pink: {
      "50": "#fef5fa",
      "100": "#fcd7ea",
      "200": "#fab5d8",
      "300": "#f784be",
      "400": "#f55ca9",
      "500": "#ee0078",
      "600": "#cd0067",
      "700": "#a90055",
      "800": "#850043",
      "900": "#640032",
    },
    red: {
      "50": "#fff5f6",
      "100": "#ffd6db",
      "200": "#ffb1b9",
      "300": "#ff7e8b",
      "400": "#ff5a6b",
      "500": "#e24151",
      "600": "#bf3745",
      "700": "#9a2c38",
      "800": "#83252f",
      "900": "#5f1b22",
    },
    orange: {
      "50": "#fefaf5",
      "100": "#fdead8",
      "200": "#fad2aa",
      "300": "#f5ad65",
      "400": "#f1871e",
      "500": "#db6d00",
      "600": "#b95c00",
      "700": "#944900",
      "800": "#743a00",
      "900": "#603000",
    },
    yellow: {
      "50": "#fffefb",
      "100": "#fdf9e5",
      "200": "#faeeb0",
      "300": "#f6df6f",
      "400": "#f0c908",
      "500": "#c6a500",
      "600": "#9f8400",
      "700": "#7c6700",
      "800": "#5d4d00",
      "900": "#4d4000",
    },
    green: {
      "50": "#eafbf4",
      "100": "#a7efd0",
      "200": "#49de9d",
      "300": "#34bb81",
      "400": "#2ea873",
      "500": "#278d61",
      "600": "#217752",
      "700": "#1b6042",
      "800": "#165138",
      "900": "#103b28",
    },
    teal: {
      "50": "#eafefe",
      "100": "#96f8f8",
      "200": "#00ecec",
      "300": "#00d3d3",
      "400": "#00b3b3",
      "500": "#009999",
      "600": "#007c7c",
      "700": "#006161",
      "800": "#005151",
      "900": "#004242",
    },
    cyan: {
      "50": "#f1fcfe",
      "100": "#c1f1fb",
      "200": "#a3ebf9",
      "300": "#7fe3f7",
      "400": "#00c5ec",
      "500": "#00b5d9",
      "600": "#00a3c4",
      "700": "#0087a2",
      "800": "#006f85",
      "900": "#005667",
    },
    blue: {
      "50": "#f4f7fe",
      "100": "#d3e1fc",
      "200": "#acc6fa",
      "300": "#7da5f7",
      "400": "#6192f5",
      "500": "#3977f3",
      "600": "#145df0",
      "700": "#0045d2",
      "800": "#003bb2",
      "900": "#002b82",
    },
  },
  templates: [
    {
      type: "free-talk",
      titleKey: "dashboard.template.freeTalk.title",
      descriptionKey: "dashboard.template.freeTalk.description",
      backgroundColor: "brand.primary.main",
      buttonBackgroundColor: "#FCFCFC",
      borderColor: "transparent",
      buttonTextColor: "black",
      descriptionTextColor: "white",
    },
    {
      type: "role-play",
      titleKey: "dashboard.template.rolePlay.title",
      descriptionKey: "dashboard.template.rolePlay.description",
      backgroundColor: "#FCFCFC",
      buttonBackgroundColor: "brand.secondary.main",
      borderColor: "brand.secondary.main",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
    {
      type: "topic",
      titleKey: "dashboard.template.topics.title",
      descriptionKey: "dashboard.template.topics.description",
      backgroundColor: "#FCFCFC",
      buttonBackgroundColor: "brand.red.400",
      borderColor: "brand.red.400",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
    {
      type: "quiz",
      titleKey: "dashboard.template.quiz.title",
      descriptionKey: "dashboard.template.quiz.description",
      backgroundColor: "#FCFCFC",
      buttonBackgroundColor: "brand.green.300",
      borderColor: "brand.green.300",
      buttonTextColor: "black",
      descriptionTextColor: "black",
    },

    {
      type: "case",
      titleKey: "dashboard.template.caseStudy.title",
      descriptionKey: "dashboard.template.caseStudy.description",
      backgroundColor: "#FCFCFC",
      buttonBackgroundColor: "brand.primary.main",
      borderColor: "brand.primary.main",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
  ],
};
