import { useEffect } from "react";
import { getVoicesFromLang } from "../services/speech/text-to-speech-config";
import { AppError } from "../utils/app-error";
import { logger } from "../utils/logger";
import { useSettings } from "./use-settings";
import { useSimpleToast } from "./use-simple-toast";

export function useVoiceSelection() {
  const { settings, settingsAreLoading, initialSettingsSaved, saveSettingsPartial } = useSettings();
  const { toastFail } = useSimpleToast();

  useEffect(() => {
    if (!settingsAreLoading && settings?.practiceLanguage && initialSettingsSaved) {
      const practiceLanguage = settings.practiceLanguage;

      getVoicesFromLang(practiceLanguage)
        .then((result) => {
          /**
           * Note: checking if the practiceLanguage langCode is in the voicePreference ID string. Jeez, who made this?
           * Fallback to handle mismatch case between practiceLanguage and voicePreference.
           */
          if (
            !settings.voicePreference?.toLowerCase().includes(practiceLanguage.toLowerCase()) &&
            result[0]
          ) {
            logger.verbose("Set default voice preference");
            saveSettingsPartial({ voicePreference: result[0].id });
          }
        })
        .catch((err) => {
          let message = "Unable to contact server";
          if (err instanceof AppError) {
            message = err.publicMessage;
          }
          toastFail(message);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings?.practiceLanguage, settingsAreLoading, initialSettingsSaved]);
}
