import { siteConfig } from "../configuration/config";
import { Word } from "./flashcard";

export const authTarget = siteConfig.useTokenAuth ? "token-auth" : "auth0";

export const FLASHCARD_STORAGE_KEY = "flashcard-selected-words";
export const FLASHCARD_STOPPED_AT = "flashcard-stopped-at";

export const MAX_FLASHCARD_ITEMS = 100;
export const defaultSelectedWords: Word[] = [];
export const appSignInRedirect = "/authentication/signin";
export const orgSigninPage = "/authentication/signin-organization";

export const HIDE_CHAT_SIDEBAR_KEY = "hide-chat-sidebar";

export const MAX_READSPEED = 5;
export const MIN_READSPEED = 1;
export const DEFAULT_READSPEED = 3;

export const DEFAULT_TRIGGER_WORD = "Send";
export const STRING_DELIMITER = "||";
export const ALLOWED_IMAGE_TYPES = ["image/jpeg", "image/png", "image/gif"];
