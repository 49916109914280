import useSWR from "swr";
import { OrganizationSettingsDTO } from "../database/entity/OrganizationSettings";
import { fetchApi } from "../services/fetch-api";
import { AppError } from "../utils/app-error";

export function useOrganizationSettings() {
  const response = useSWR<OrganizationSettingsDTO, AppError>(`/organization/settings`, fetchApi, {
    shouldRetryOnError: false,
  });

  return {
    organizationSettings: {
      supportedLanguages: response.data?.supportedLanguages,
      enabledDefaultPromptTemplates: response.data?.enableDefaultPromptTemplates,
    },
    organizationSettingsAreLoading: !response.data && response.isLoading,
  };
}
